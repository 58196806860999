import { Model } from '@vuex-orm/core'
import Deal from "@/state/entities/deal";
import moment from "moment/moment";
import ExchangeConnection from "@/state/entities/exchangeConnection";

export default class Bot extends Model {
    static entity = 'bots'
    static primaryKey = '_id'

    static fields () {
        return {
            _id: this.attr(null),
            created_at: this.attr(''),
            user_id: this.attr(''),
            title: this.attr(''),
            base_currency: this.attr(''),
            enabled: this.boolean(false),
            exchange_connection_id: this.number(null),
            max_active_deals: this.number(null),
            active_deals: this.number(null),
            deals: this.hasMany(Deal, 'bot_id'),
            archived: this.boolean(false),
            exchange_connection: this.belongsTo(ExchangeConnection, 'exchange_connection_id', '_id'),
        }
    }

    get created_date () {
        return moment(this.created_at).format('YYYY-MM-DD')
    }

    get created_time () {
        return moment(this.created_at).format('HH:mm')
    }

    static apiConfig = {
        actions: {
            enable (id) {
                return this.post(`/bot/${id}/enable`).then((res) => {
                    if(res.response.status === 201) {
                        Bot.update({
                            where: id,
                            data: {
                                enabled: true
                            }
                        })
                    }
                    return Promise.resolve(res)
                })
            },
            disable (id) {
                Bot.update({
                    where: id,
                    data: {
                        enabled: false
                    }
                })
                return this.post(`/bot/${id}/disable`)
            },
            archive (id) {
                Bot.update({
                    where: id,
                    data: {
                        archive: true
                    }
                })
                return this.post(`/bot/${id}/archive`)
            }
        }
    }
}
